import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    _id: '',
    membership: '',
    fullName: '',
    email: '',
    mobileNumber: '',
    password: '',
    profilePic: '',
    token: '',
    onlineUser: [],
    socketConnection: null,
}

const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            const { _id, fullName, email, profilePic } = action.payload
            state._id = _id || state._id
            state.fullName = fullName || state.fullName
            state.email = email || state.email
            state.profilePic = profilePic || state.profilePic
        },
        setToken: (state, action) => {
            state.token = action.payload || state.token
        },
        logout: (state) => {
            state._id = ''
            state.fullName = ''
            state.email = ''
            state.profilePic = ''
            state.token = ''
            state.socketConnection = null
        },
        setOnlineUser: (state, action) => {
            state.onlineUser = action.payload || []
        },
        setSocketConnection: (state, action) => {
            state.socketConnection = action.payload || null
        },
    },
})

export const { setUser, setToken, logout, setOnlineUser, setSocketConnection } =
    UserSlice.actions
export default UserSlice.reducer
